<template>
      <v-container
        id="street-lighting"
        fluid
        tag="section"
    >
        <v-alert
      dense
      text
      type="success"
      dismissible
      v-model="alert"
    >
      Section 6 <strong>saved</strong> successfully.
    </v-alert>
        <v-row justify="center">
            <v-col
            cols="12"
            md="12"
            >

                <base-material-card>
                    <template v-slot:heading>
                      <div class="display-2 font-weight-light">
                      Section 6: Glass & Mirror Inc.
                      </div >
                      <div class="display- font-weight-light"> 
                      10001 Baltimore Street N.E. Blaine, 55449	763-784-1705
                      </div>
                      <div class="display- font-weight-light">
                      (Time Frame- 6 Weeks)
                      </div>
                    </template>
                    <v-form>
                        <v-container class="py-0">
                          <v-row>
                            <v-col
                            cols="12"
                            md="6"
                            >
                                <v-text-field
                                    label="Homeowner(s)"
                                    color="black"
                                    v-model = homeowners
                                />
                            </v-col>
                            <v-col
                            cols="12"
                            >
                                <v-text-field
                                    label="Address & Legal"
                                    color="black"
                                    v-model="this.selected_user.Address"
                                />
                            </v-col>
                            <v-col
                            cols="16"
                            >
                                <v-text-field
                                    label="Phone"
                                    color="black"
                                    v-model="this.selected_user.HomeNumber"
                                />
                            </v-col>
                            <v-col
                            cols="6"
                            >
                                <v-text-field
                                    label="Emails"
                                    color="black"
                                    v-model="emails"
                                />
                            </v-col>
                          </v-row>
                            <v-row>
                                <v-col cols="12">
                                  <v-header><strong>MASTER OPTIONAL SHOWER DOOR</strong></v-header>
                                </v-col>
                                <v-col cols="4"><v-select label="Shower Glass" :items="['No', 'Clear','Obscured','Other','Undecided']" v-model="section.column_5"></v-select></v-col>
                                <v-col cols="4"><v-select label="Shower Trim" :items="['Frameless','Chrome','ORB','BN','Other','Undecided']" v-model="section.column_32"></v-select></v-col>
                                <v-col cols="4"><v-select label="STD Frameless Vanity Mirror Trim" :items="['Chrome','ORB','BN','Other','Undecided']" v-model="section.column_6"></v-select>  	</v-col>
                                <v-col cols="4"><v-select label="Homeowner to provide own Mirror" :items="['Yes','No','Undecided']" v-model="section.column_7"></v-select></v-col>
                                <v-col cols="4"><v-select label="Shower Door" :items="['Yes','No','Undecided']" v-model="section.column_8"></v-select>	</v-col>
                                <v-col cols="4"><v-select label="Type of Door"  :items="['Frameless','Framed','Other','Undecided']" v-model="section.column_9"></v-select> 	</v-col>

                                <v-col cols="12">
                                  <v-header><strong>MAIN BATHROOM SHOWER DOOR</strong></v-header>
                                </v-col>
                                <v-col cols="4"><v-select label="Shower Glass" :items="['NO', 'Clear','Obscured','Other','Undecided']" v-model="section.column_10" ></v-select></v-col>
                                <v-col cols="4"><v-select label="Shower Trim" :items="['Frameless','Chrome','ORB','BN','Other','Undecided']" v-model="section.column_11"></v-select>  	</v-col>
                                <v-col cols="4"><v-select label="STD Frameless Vanity Mirror Trim" :items="['Chrome','ORB','BN','Other','Undecided']" v-model="section.column_12"></v-select>  	</v-col>
                                <v-col cols="4"><v-select label="Homeowner to provide own Mirror" :items="['Yes','No','Undecided']" v-model="section.column_13"></v-select>  			</v-col>
                                <v-col cols="4"><v-select label="Shower Door" :items="['Yes','No','Undecided']" v-model="section.column_14"></v-select>	</v-col>
                                <v-col cols="4"><v-select label="Type of Door"  :items="['Frameless','Framed','Other','Undecided']" v-model="section.column_15"></v-select> 	</v-col>

                                <v-col cols="12">
                                  <v-header><strong>ADDITIONAL BATHROOM OPTIONS SHOWER DOOR</strong></v-header>
                                </v-col>
                                <v-col cols="12"><v-text-field label="Location"  ></v-text-field></v-col>
                                <v-col cols="4"><v-select label="Shower Glass" :items="['NO', 'Clear','Obscured','Other','Undecided']" v-model="section.column_16"></v-select></v-col>
                                <v-col cols="4"><v-select label="Shower Trim" :items="['Frameless','Chrome','ORB','BN','Other','Undecided']" v-model="section.column_17"></v-select>  	</v-col>
                                <v-col cols="4"><v-select label="STD Frameless Vanity Mirror Trim" :items="['Chrome','ORB','BN','Other','Undecided']" v-model="section.column_18"></v-select>  	</v-col>
                                <v-col cols="4"><v-select label="Homeowner to provide own Mirror" :items="['Yes','No','Undecided']" v-model="section.column_19"></v-select>  			</v-col>
                                <v-col cols="4"><v-select label="Shower Door" :items="['Yes','No','Undecided']" v-model="section.column_20"></v-select>	</v-col>
                                <v-col cols="4"><v-select label="Type of Door"  :items="['Frameless','Framed','Other','Undecided']" v-model="section.column_21"></v-select> 	</v-col>

                                <v-col cols="12">
                                  <v-header><strong>ADDITIONAL BASEMENT BATHROOM OPTIONS</strong></v-header>
                                </v-col>
                                <v-col cols="4"><v-select label="Shower Glass" :items="['NO', 'Clear','Obscured','Other','Undecided']" v-model="section.column_22"></v-select></v-col>
                                <v-col cols="4"><v-select label="Shower Trim" :items="['Frameless','Chrome','ORB','BN','Other','Undecided']" v-model="section.column_23"></v-select>  	</v-col>
                                <v-col cols="4"><v-select label="STD Frameless Vanity Mirror Trim" :items="['Chrome','ORB','BN','Other','Undecided']" v-model="section.column_24"></v-select>  	</v-col>
                                <v-col cols="4"><v-select label="Homeowner to provide own Mirror" :items="['Yes','No','Undecided']" v-model="section.column_25"></v-select>  			</v-col>
                                <v-col cols="4"><v-select label="Shower Door" :items="['Yes','No','Undecided']" v-model="section.column_26"></v-select>	</v-col>
                                <v-col cols="4"><v-select label="Type of Door"  :items="['Frameless','Framed','Other','Undecided']" v-model="section.column_27"></v-select> 	</v-col>
                      <v-dialog
                        v-model="dialog"
                        max-width="50%"
                      >
                      <template v-slot:activator="{ on, attrs }">
                        <v-col  cols="12" class="text-right">
                        <div v-if="permissions.scope === 'create:users,fullaccess:staff'">
                        <v-btn
                          color="primary"
                          dark
                          class="text-right"
                          v-bind="attrs"
                          v-on="on"
                        >
                          Submit
                        </v-btn>
                      </div>
                        </v-col>
                      </template>
                      <v-card>
                        <v-card-title>Add Vendor Email</v-card-title>
                        <v-card-actions>
                        <v-card-text>
                          <v-container>
                            <v-form
                                ref="form"
                                v-model="valid"
                                lazy-validation
                              >
                              <v-row>
                                <v-col
                                     cols="12"
                                     md="4">

                                  <v-select
                                      v-model ="add_vendor_email"
                                     label="Add Vendor Email"
                                     :items="['Yes', 'No']"
                                      @change="add_vendor_email"
                                ></v-select>
                                 </v-col>
                              </v-row>
                            </v-form>
                          </v-container>
                        </v-card-text>
                        </v-card-actions>
                      </v-card>
                             <v-expand-transition>
                          <v-card
                          v-if="add_vendor_email === 'Yes'"
                          class="transition-fast-in-fast-out v-card--reveal"
                          style="height: 100%;"
                        >
                            <v-card-text>
                            <v-container>
                              <v-form
                                ref="form"
                                v-model="valid"
                                lazy-validation
                              >
                              <v-row>

                               <!-- Partner 1 -->

                               <v-col
                                cols="12"
                                md="13"
                                class="card-title font-weight-light pt-6">
                                Vendor Email
                               </v-col>
                                <v-autocomplete
                                ref="user"
                                :items="vendor_email"
                                v-model="selected_vendor.Email"
                                item-text="Email"
                                label="Vendor Email"
                                prepend-icon="mdi-account-search"
                                outlined
                                solo
                                menu-props="auto"
                                class="px-3"
                                required
                                return-object
                                ></v-autocomplete>
                              </v-row>
                              </v-form>
                            </v-container>
                            </v-card-text>
                            <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                              color="blue darken-1"
                              text
                              :disabled="!valid"
                              @click="sectionComplete"
                            >
                              Submit
                            </v-btn>
                            <v-btn
                              color="blue darken-1"
                              text
                              @click="close"
                            >
                              Close
                            </v-btn>
                          </v-card-actions>
                          </v-card>
                               <v-card
                          v-if="add_vendor_email === 'No'"
                          class="transition-fast-in-fast-out v-card--reveal"
                          style="height: 100%;"
                        >
                                 <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                              color="blue darken-1"
                              text
                              :disabled="!valid"
                              @click="sectionComplete"
                            >
                              Save
                            </v-btn>
                            <v-btn
                              color="blue darken-1"
                              text
                              @click="close"
                            >
                              Close
                            </v-btn>
                          </v-card-actions>
                          </v-card>
                             </v-expand-transition>
                           </v-dialog>
                            </v-row>
                        </v-container>
                    </v-form>
                </base-material-card>
              <base-material-card>
                <template v-slot:heading>
                    <div class="display-2 font-weight-light">
                    Signature
                    </div>
                </template>
                <v-form>
                    <v-container class="py-0">

                         <v-row>
                                <v-col cols="12" md="3">
                                    <v-text-field label="Homeowner 1"
                                     v-model="section.column_28"/>
                                </v-col>
                                <v-col cols="12" md="3">
                                    <v-text-field type="date"
                                     v-model="section.column_29"/>
                                </v-col>
                                <v-col cols="12" md="3">
                                    <v-text-field label="Homeowner 2"
                                     v-model="section.column_30"/>
                                </v-col>
                                <v-col cols="12" md="3">
                                    <v-text-field type="date"
                                     v-model="section.column_31"/>
                                </v-col>
                           <v-col cols="12" class="text-right">
                        <v-btn color="success" class="mr-0"
                        @click="updateSection">Save</v-btn>
                    </v-col>
                         </v-row>
                             </v-container>
                          </v-form>
                </base-material-card>

            </v-col>
        </v-row>
    </v-container>
</template>


<script>
import axios from "axios";

export default {
  name: 'Section6',
    data() {
    return {
      home_id: localStorage.getItem("homeid"),
      alert:false,
      dialog: null,
      valid: null,
      staff_email: 'steve@skelleymarketing.com',
      section_id: "6",
      add_vendor_email:'',
      vendor_email: [],
        selected_vendor: {
            ItemId:0,
            Vendor: '',
            Name:'',
            Email: '',
            ModifiedDate:''
        },
        default_vendor: {
            ItemId:0,
            Vendor: '',
            Name:'',
            Email: '',
            ModifiedDate:''
        },
      section: {
        column_1:'',
        column_2:'',
        column_3:'',
        column_4:'',
        column_5:'',
        column_32:'',
        column_6:'',
        column_7:'',
        column_8:'',
        column_9:'',
        column_10:'',
        column_11:'',
        column_12:'',
        column_13:'',
        column_14:'',
        column_15:'',
        column_16:'',
        column_17:'',
        column_18:'',
        column_19:'',
        column_20:'',
        column_21:'',
        column_22:'',
        column_23:'',
        column_24:'',
        column_25:'',
        column_26:'',
        column_27:'',
        column_28:'',
        column_29:'',
        column_30:'',
        column_31:'',
      },
      selected_user: {
          PrimaryEmailAddress:'',
          SecondaryEmailAddress:'',
          PrimaryFirstName: '',
          PrimaryLastName: '',
          SecondaryFirstName: '',
          SecondaryLastName: '',
          Address:'',
          City:'',
          State: '',
          Zip: '',
          BuildStart: '',
          BuildEnd:'',
          CellNumber:'',
          HomeNumber: '',
          WorkPhone:'',
          Role:'',
          Agent:''
      },
    }
  },
  created () {
    this.getSection()
    this.getVendorEmail()
    this.getUserInfo()
    },
    methods:{

        getSection() {
            axios
            .get(process.env.VUE_APP_GET_SECTION_URL,
            {
            params: {
                home_id: this.home_id,
                section_id: this.section_id
            }
            })
            .then(response => {

                if (response.data)
                {
                    var result = response.data
                    this.section = JSON.parse(result['JsonDetails'])
                }
            })
            .catch(error => {
            console.log(error)
            })
        },
      getUserInfo() {
            axios
            .get(process.env.VUE_APP_GET_USER_URL_INFO,
            {
              params: {
                home_id: this.home_id
            }
            })
            .then(response => {

                if (response.data)
                {
                    var result = response.data
                    this.selected_user = result[0]
                }
            })
            .catch(error => {
            console.log(error)
            })
        },
      getVendorEmail () {
          axios.get(process.env.VUE_APP_GET_VENDOR_EMAIL_URL,{})
          .then(response => {
            this.vendor_email = response.data
          })
          .catch(error => {
            console.log(error)
          })
        },
        updateSection(){
            var body ={
              'home_id':this.home_id,
              'section_id': this.section_id,
              "section":JSON.stringify(this.section),
            }
            axios
            .post(process.env.VUE_APP_UPDATE_SECTION_URL,body)
            .then(response => {
                console.log(response.data)
                this.alert = true
            })
            .catch(error => {
            console.log(error)
            })
        },
      sectionComplete(){
        var body ={
            'home_id':this.home_id,
            'section_id': this.section_id,
            "section":JSON.stringify(this.section),
        }
        const theNewContent = {
            homeOwner: {
                ...this.selected_user
            },
            sectionData: [
                { 
                    Title:"Master - Shower Glass",
                    Value: this.section.column_5
                },
                { 
                    Title:"Master - Shower Trim",
                    Value: this.section.column_32
                },
                { 
                    Title:"Master - STD Frameless Vanity Mirror Trim",
                    Value: this.section.column_6
                },
                { 
                    Title:"Master - Homeowner to provide own Mirror",
                    Value: this.section.column_7
                },
                { 
                    Title:"Master - Shower Door",
                    Value: this.section.column_8
                },
                { 
                    Title:"Master - Type of Door",
                    Value: this.section.column_9
                },
                { 
                    Title:"MAIN - Shower Glass",
                    Value: this.section.column_10
                },
                { 
                    Title:"MAIN - Shower Trim",
                    Value: this.section.column_11
                },
                { 
                    Title:"MAIN - STD Frameless Vanity Mirror Trim",
                    Value: this.section.column_12
                },
                { 
                    Title:"MAIN - Homeowner to provide own Mirror",
                    Value: this.section.column_13
                },
                { 
                    Title:"MAIN - Shower Door",
                    Value: this.section.column_14
                },
                { 
                    Title:"MAIN - Type of Door",
                    Value: this.section.column_15
                },
                { 
                    Title:"ADDITIONAL BATHROOM - Shower Glass",
                    Value: this.section.column_16
                },
                { 
                    Title:"ADDITIONAL BATHROOM - Shower Trim",
                    Value: this.section.column_17
                },
                { 
                    Title:"ADDITIONAL BATHROOM - STD Frameless Vanity Mirror Trim",
                    Value: this.section.column_18
                },
                { 
                    Title:"ADDITIONAL BATHROOM - Homeowner to provide own Mirror",
                    Value: this.section.column_19
                },
                { 
                    Title:"ADDITIONAL BATHROOM - Shower Door",
                    Value: this.section.column_20
                },
                { 
                    Title:"ADDITIONAL BATHROOM - Type of Door",
                    Value: this.section.column_21
                },
                { 
                    Title:"ADDITIONAL BASEMENT BATHROOM - Shower Glass",
                    Value: this.section.column_22
                },
                { 
                    Title:"ADDITIONAL BASEMENT BATHROOM - Shower Trim",
                    Value: this.section.column_23
                },
                { 
                    Title:"ADDITIONAL BASEMENT BATHROOM - STD Frameless Vanity Mirror Trim",
                    Value: this.section.column_24
                },
                { 
                    Title:"ADDITIONAL BASEMENT BATHROOM - Homeowner to provide own Mirror",
                    Value: this.section.column_25
                },
                { 
                    Title:"ADDITIONAL BASEMENT BATHROOM - Shower Door",
                    Value: this.section.column_26
                },
                { 
                    Title:"ADDITIONAL BASEMENT BATHROOM - Type of Door",
                    Value: this.section.column_27
                },
                { 
                    Title:"Homeowner #1",
                    Value: this.section.column_28
                },
                { 
                    Title:"Date",
                    Value: this.section.column_29
                },
                { 
                    Title:"Homeowner #2",
                    Value: this.section.column_30
                },
                { 
                    Title:"Date",
                    Value: this.section.column_31
                },
            ],
            vendorData: {
                ...this.selected_vendor
            }
        }
        axios
        .post(process.env.VUE_APP_UPDATE_SECTION_TWO_URL,body)
        .then(() => {
            this.alert = true
            
            if (this.add_vendor_email[0] === 'Y')
            this.sendEmail([this.selected_vendor.Email.Email,this.staff_email],this.staff_email,"vendor_section_submit_notification",theNewContent, this.section_id)
            })
            
        .catch(error => {
            console.log(error)
        })
        this.add_vendor_email = Object.assign({}, this.add_vendor_email)
        this.editedIndex = -1
        this.close()
        },
      close () {
          this.dialog = false
          this.$nextTick(() => {
            this.add_vendor_email = Object.assign({}, this.add_vendor_email)
            this.editedIndex = -1
          })
        },
      sendEmail(to,from,template,content,section){

        var email_body ={
          "to": to,
          "from": from,
          "template_name": template,
          "template_data": {
            "content": content,
            "section": section
          }
        }

        axios
        .post(process.env.VUE_APP_SEND_EMAIL_URL,email_body)
        .then(response => {
          console.log(response.data)
        })
        .catch(error => {
          console.log(error)
        })

      }
    },
  computed:{
    homeowners(){
      return `${this.selected_user.PrimaryFirstName} ${this.selected_user.PrimaryLastName} ${this.selected_user.SecondaryFirstName} ${this.selected_user.SecondaryLastName}`
      },
    emails(){
      return `${this.selected_user.PrimaryEmailAddress}, ${this.selected_user.SecondaryEmailAddress}`
      },
    homeowner_emailid() {
        return this.selected_user.PrimaryEmailAddress
      },
      permissions(){
        return {scope: localStorage.getItem("permissions")}
      }
  }
}
</script>